.ant-btn-primary {
  background-color: #20132A;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(242, 236, 255, 0.1);
  border-left-color: #e7ecf7;
  animation: spin 1s linear infinite;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
